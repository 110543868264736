import anime from 'animejs/lib/anime.es.js';

export function initBigLoaderBasic(listeIms) {
    let img = listeIms,
        c = 0,
        tot = img.length;

    let animeDelay = 200;
    if(tot <= 3) animeDelay = 500;
    
    let hideLoader = anime.timeline({ autoplay: false });
    hideLoader.add({
        targets: '#loader_wrap',
        opacity: 0,
        duration: 500,
        delay: animeDelay,
        easing: 'cubicBezier(.42,0,1,1)'
    });
    
    if (tot == 0) return doneLoading();
    

    function doneLoading() {
        hideLoader.play();
        hideLoader.finished.then(function() {
            $("#loader_wrap").fadeOut(50);
            $("#main").trigger("doneLoading");
        });
    }

    function imgLoaded() {
        c += 1;
        var perc = ((100 / tot * c) << 0) + "%";
        if (c === tot) return doneLoading();
    }

    for (var i = 0; i < tot; i++) {
        var tImg = new Image();
        tImg.onload = imgLoaded;
        tImg.onerror = imgLoaded;
        tImg.src = img[i].src;
    }
}
