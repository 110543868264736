import anime from 'animejs/lib/anime.es.js';


export function initFixedNav(fixedNav) {
    window.addEventListener('scroll', function (e) {
        if ($(window).scrollTop() > 100) {
            fixedNav.addClass("visible");
        } else {
            fixedNav.removeClass("visible");
        }
    });
}

export function initSmsNav() {
    $(".js-openNav").on('click', function (e) {
        e.stopImmediatePropagation();

        anime({
            targets: '.js-menu',
            translateX: ['-100%', '0'],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)',
            complete: function (anim) {
                $("#menuSms").addClass("opened");
                $(".js-menu").attr('aria-hidden', false);
                $(".js-openNav").each(function () {
                    $(this).attr('aria-expanded', true);
                });
            }
        });
        return false;
    });

    $(".js-closeNav").on('click', function (e) {
        e.stopImmediatePropagation();
        if (!$('#menuSms').hasClass('opened')) return false;
        anime({
            targets: '.js-menu',
            translateX: ['0', '-100%'],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)',
            complete: function (anim) {
                $("#menuSms").removeClass("opened");
                $(".js-menu").attr('aria-hidden', true);
                $(".js-openNav").each(function () {
                    $(this).attr('aria-expanded', false);
                });
            }
        });
        return false;
    });

    $(".smsMenu .withSubNav .mainNav__link ").on("click", function (e) {
        e.stopImmediatePropagation();
        let ssNav = $(this).parent().find(".nav__subNav");
        if (ssNav.hasClass("opened")) {
            ssNav.slideUp(400, function () { ssNav.removeClass("opened") });
        } else {
            ssNav.slideDown(400, function () { ssNav.addClass("opened") });
        }
        return false;
    });

}


export function closeNav() {
    anime({
        targets: '.js-menu',
        translateX: ['0', '-100%'],
        duration: 500,
        easing: 'cubicBezier(.215,.61,.355,1)',
        complete: function (anim) {
            $("#menuSms").removeClass("opened");
            $(".js-menu").attr('aria-hidden', true);
            $(".js-openNav").each(function () {
                $(this).attr('aria-expanded', false);
            });
        }
    });
}