import parsley from 'parsleyjs';
import 'parsleyjs/dist/i18n/fr';

export function initForm(form) {
	console.log("init");

    form.parsley().on('field:validated', function() {
            var ok = $('.parsley-error').length === 0;
        })
        .on('form:submit', function() {

            form.find("input[type=submit]").attr('disabled', true);
            form.find("input[type=submit]").addClass("button--loading");

            var url = form.data('url') + "utils/";
            var action = form.data('action');
            var data = $(form).serialize() + "&action=" + action;
          	
            $.post(url, data, function(result) {

                $('.button--loading').removeClass("button--loading");

                if (result.code == 1) {
                    form.append("<div class='alert alert--success'>" + result.message + "</div>");
                } else {
                    form.append("<div class='alert alert--error'>" + result.message + "</div>");
                }

                $('.alert').fadeIn("slow");

                $('.alert').on('click', function() {
                    $(this).fadeOut(400, function() { $(this).remove(); });
                });

                window.setTimeout(function() {
                    $('.alert').fadeOut('slow', function() { $(this).remove(); })
                }, 5000);

                form.find("input[type=submit]").attr('disabled', false);
            }, 'json');

            return false;
        });
}