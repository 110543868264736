import justifiedGallery from 'justifiedGallery';
import Splide from '@splidejs/splide';
import device from "current-device";

export function initGallery(gal, height, justi, gap = 10) {

    if (!device.desktop()) {
        gap = 10;
    }
    $(gal).justifiedGallery({
        rowHeight: height,
        maxRowHeight: '120%',
        lastRow: 'center',
        margins: gap,
        captions: false,
        lastRow: justi,
        sizeRangeSuffixes: {
            'lt500': '-small',
            'lt800': '-medium',
            'lt1100': '-large'
        }
    });


    $(".filter").on("click", function () {
        if ($(this).hasClass("cur")) return false;
        $(".filter.cur").removeClass("cur");
        let flt = "." + $(this).data("filter");
        filterGalDesktop(gal, flt);
        $(this).addClass("cur");
    });

}

export function initGalleryFilter(gal) {
    $(".filter").on("click", function () {
        if ($(this).hasClass("cur")) return false;
        $(".filter.cur").removeClass("cur");
        let flt = "." + $(this).data("filter");
        filterGalTablet(gal, flt);
        $(this).addClass("cur");
    });
}

function filterGalDesktop(gal, flt) {
    $(gal).justifiedGallery({ filter: flt });
}

function filterGalTablet(gal, flt) {
    let projectsOk = $(flt);
    let projetcsNot = $(".galerie__item").not(flt);

    projetcsNot.hide();
    projectsOk.show();
}

export function resetGallery(gal) {
    $(gal).justifiedGallery("destroy");
}


export function initGalSplide(slide) {
    let galSplide = new Splide(slide, {
        perPage: 3,
        padding: { left: '10%' },
        gap: '1.5rem',
        arrows: false,
        pagination: false,
        breakpoints: {
            1200: {
                perPage: 2,
                padding: { left: '10%' },
                gap: '1.5rem',
            },
            750: {
                perPage: 1,
                padding: { left: '8%' },
                padding: { right: '15%' },
                gap: '1.5rem',
            }
        }
    });
    galSplide.mount();
    return galSplide;
}


export function initGalSplideDesk(slide) {
    let galSplide = new Splide(slide, {
        perPage: 3,
        padding: { left: '10%' },
        gap: '1.5rem',
        arrows: false,
        pagination: false,
        breakpoints: {
            10000: {
                destroy: true,
            }
        }
    });
    galSplide.mount();
    return galSplide;
}


export function loadProjects(gal, nbVisible, nbToLoad) {
    var url = $(gal).data('url') + "utils/";
    var action = "loadProject";
    var data = "action=" + action + "&start=" + nbVisible + "&limit=" + nbToLoad;

    $(".js-showMore").attr('disabled', true);

    $.post(url, data, function (result) {
        if (result.code == 1) {
            $(gal).data('visible', result.visible);
            $(gal).append(result.message);
            $(gal).justifiedGallery('norewind');
            if (result.visible >= $(gal).data("total")) {
                $(".js-showMore").fadeOut();
            } else {
                $(".js-showMore").attr('disabled', false);
            }
        } else {
            $(gal).after("<div class='alert alert--error'>" + result.message + "</div>");
            $(".alert").fadeIn();
        }
    });
}