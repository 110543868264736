import "es6-shim";
import 'nodelist-foreach-polyfill';
import "objectFitPolyfill";
import "scrollingelement";

import device from "current-device";
import anime from 'animejs/lib/anime.es.js';
import modularScroll from 'modularscroll';
import Splide from '@splidejs/splide';
import * as Fresco from '@staaky/fresco';
import barba from '@barba/core';

import * as Gal from './_gal.js';
import * as Form from './_form.js';
import * as Loader from './_loader.js';
import * as Nav from './_nav.js';

function initScrollDetect() {
    scroll = new modularScroll();
}


jQuery(document).ready(function ($) {

    let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
    anime({
        targets: scrollElement,
        scrollTop: 0,
        duration: 1
    });

    Loader.initBigLoaderBasic($("body").find("img.toPreload"));

    $(".js-goToTop").on("click", function (e) {
        e.stopImmediatePropagation();
        let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
        anime({
            targets: scrollElement,
            scrollTop: 0,
            duration: 1000,
            easing: 'cubicBezier(.215,.61,.355,1)'
        });
        return false;
    });


    Nav.initFixedNav($(".navFixed"));
    Nav.initSmsNav();

    barba.init({
        debug: true,
        timeout: 1000,
        transitions: [{
            name: 'default-transition',
            leave(data) {
                var done = this.async();
                if ($('#menuSms').hasClass('opened')) Nav.closeNav();

                $("#loader_wrap").fadeIn(100, function () {

                    anime({
                        targets: '#loader_wrap',
                        opacity: 1,
                        duration: 400,
                        easing: 'cubicBezier(.33,.23,.13,.9)',
                        complete: function () {

                            //scroll.destroy();

                            $(data.current.container).fadeOut();
                            $(".mainNav__item.cur").removeClass("cur");

                            if (data.trigger != "back" && data.trigger != "forward") {
                                let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
                                anime({
                                    targets: scrollElement,
                                    scrollTop: 0,
                                    duration: 1
                                });
                            }

                            done();
                        }
                    });
                });
            },
            enter(data) {
                var done = this.async();

                Loader.initBigLoaderBasic($(data.next.container).find("img.toPreload"));
                $("#main").on('doneLoading', function () {
                    if (data.next.namespace == "projet") {
                        $(".mainNav__item.pageLink_projets").addClass("cur");
                    } else if (data.next.namespace == "prestation") {
                        $(".mainNav__item.pageLink_prestations").addClass("cur");
                    } else {
                        $(".mainNav__item.pageLink_" + data.next.namespace).addClass("cur");
                    }
                    done();
                });
            }
        }],
        views: [{
            namespace: 'home',
            beforeEnter: function (data) {

                // si desktop
                if (device.desktop()) {
                    Gal.initGallery($(data.next.container).find("#galerieJustified"), $(data.next.container).find("#galerieJustified").data("rowheight"), $(data.next.container).find("#galerieJustified").data("justify"));
                    Gal.initGalSplideDesk(document.querySelector('.projectsSlider'));
                }

                //si tablet
                if (device.tablet()) {
                    Gal.initGalSplide(document.querySelector('.projectsSlider'));
                }

                // si mobile
                if (device.mobile()) {
                    Gal.initGalSplide(document.querySelector('.projectsSlider'));
                }


                if ($(".avisSlider").length > 0) {
                    new Splide('.avisSlider', {
                        type: 'loop',
                        pagination: false,
                        padding: { left: 30, right: 30 },
                        gap: 30,
                        classes: {
                            arrows: 'splide__arrows elea-arrows',
                            arrow: 'splide__arrow elea-arrow',
                            prev: 'splide__arrow--prev elea-prev',
                            next: 'splide__arrow--next elea-next',
                        },
                    }).mount();
                }
            }
        }, {
            namespace: 'projet',
            beforeEnter: function (data) {
                Gal.initGallery($(data.next.container).find("#galerieJustified"), $(data.next.container).find("#galerieJustified").data("rowheight"), $(data.next.container).find("#galerieJustified").data("justify"), $(data.next.container).find("#galerieJustified").data("gap"));
            }
        }, {
            namespace: 'projets',
            beforeEnter: function (data) {
                if (device.desktop()) {
                    Gal.initGallery($(data.next.container).find("#galerieJustified"), $(data.next.container).find("#galerieJustified").data("rowheight"), $(data.next.container).find("#galerieJustified").data("justify"));
                } else {
                    Gal.initGalleryFilter($(data.next.container).find("#galerieJustified"));
                }
            },
            afterEnter: function (data) {
                $(".js-showMore").on("click", function (e) {

                    let nbVisible = $(".galerie").data("visible");
                    let nbTotal = $(".galerie").data("total");
                    let nbToLoad = $(".galerie").data("nb");

                    if (nbVisible >= nbTotal) return false;
                    Gal.loadProjects($("#galerieJustified"), nbVisible, nbToLoad);
                });
            }
        },
        {
            namespace: 'contact',
            afterEnter: function (data) {
                Form.initForm($("#js-contactForm"));
            }
        }
        ]
    });

    barba.hooks.afterEnter((data) => {
        window.setTimeout(function () {
            if (device.desktop()) {
                initScrollDetect();
            }
        }, 200);
    });
});

